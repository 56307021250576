<template>
	<div class="" style="background-color: #f5f5f5;">
		<div>
			<img :src="agent_info.estimate_banner.url"/>
		</div>
		<reckon></reckon>
	</div>
</template>

<script>
	import reckon from './components/reckon.vue';
	import { bannerList } from '@/api/jiyun'
	export default {
		name: 'estimate',
		components: {
			reckon,
		},
		data() {
			return {
				metaInfo:{
					title: this.$t('page_title.estimate'),
				},
				agent_info: {}
			}
		},
		metaInfo() {
			return {
				title: this.metaInfo.title
			}
		},
		created() {
			this.bannerList();
		},
		methods: {
			// 代理banner
			bannerList(){
				let param = {
					domain_url: window.location.origin
				}
				bannerList(param).then((res)=>{
					this.agent_info = res.data;
					if(!this.agent_info.is_default){
						this.$Message.info(this.$t('你没有权限'))
						this.$router.push({
							path: '/'
						})
					}
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
		}
	}
</script>

<style scoped>
	
</style>