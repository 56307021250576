<template>
	<div class="_pc-pad-x-280 _pc-pad-y-70 mb-pad-x-15 mb-pad-y-25" style="background-color: #f5f5f5;">
		<div class="title _pc-font-34 _b _pc-pad-bottom-35 mb-font-25" style="color: #333333;">{{ $t('运费估算') }}</div>
		<div class="main-box display-none">
			<div class="main-box-li _flex-row-bet-mid">
				<!-- 国家 -->
				<div class="item" style="position: relative;">
					<div class="title _pc-font-20 _pc-mar-bottom-15 " style="color: #333;">{{ $t('收货地址') }}</div>
					<div class="input-box _flex-row-bet-mid _pc-pad-left-30 _pc-pad-right-15 _pc-w-630 _pc-font-18" @click="getCountrys(-1)">
						<div class="line-1" :style="country_index!==''?'color:#333':'color:#999'">
							{{country_index!==''? country_name:$t('请选择')}}
						</div>
						<div class="iconfont2 icon-xiala"></div>
					</div>
					<div class="select-box" v-if="country_show">
						<div class="li line-1" v-for="(item,index) in country_list" :key="index" :title="$t('收货地址')+'\n '+item" @click="getCountrys(index,item)">{{item.name}}</div>
					</div>
				</div>
				<!-- 重量 -->
				<div class="item">
					<div class="title _pc-font-20 _pc-mar-bottom-15" style="color: #333;">{{ $t('重量') }}</div>
					<div class="input-box _flex-row-bet-mid _pc-pad-left-30 _pc-pad-right-15 _pc-w-325 _pc-font-18">
						<input type="number" :placeholder="$t('请输入')" @keydown="NumberInput" v-model="weight">
						<div class="_pc-font-20">kg</div>
					</div>
				</div>
				<!-- 长度 -->
				<div class="item">
					<div class="title _pc-font-20 _pc-mar-bottom-15" style="color: #333;">{{ $t('长度') }}</div>
					<div class="input-box _flex-row-bet-mid _pc-pad-left-30 _pc-pad-right-15 _pc-w-325 _pc-font-18">
						<input type="number" :placeholder="$t('请输入')" @keydown="NumberInput" v-model="length">
						<div class="_pc-font-20">cm</div>
					</div>
				</div>
			</div>
			<div class="main-box-li _flex-row-mid _pc-mar-top-30">
				<!-- 宽度 -->
				<div class="item _pc-mar-right-30">
					<div class="title _pc-font-20 _pc-mar-bottom-15" style="color: #333;">{{ $t('wide') }}</div>
					<div class="input-box _flex-row-bet-mid _pc-pad-left-30 _pc-pad-right-15 _pc-w-300 _pc-font-18">
						<input type="number" :placeholder="$t('请输入')" @keydown="NumberInput" v-model="width">
						<div class="_pc-font-20">cm</div>
					</div>
				</div>
				<!-- 高度 -->
				<div class="item">
					<div class="title _pc-font-20 _pc-mar-bottom-15" style="color: #333;">{{ $t('height') }}</div>
					<div class="input-box _flex-row-bet-mid _pc-pad-left-30 _pc-pad-right-15 _pc-w-300 _pc-font-18">
						<input type="number" :placeholder="$t('请输入')" @keydown="NumberInput" v-model="height">
						<div class="_pc-font-20">cm</div>
					</div>
				</div>
				<!-- 开始预估 -->
				<div class="item" style="position: absolute;right: 0;">
					<div class="title _pc-font-20 _pc-mar-bottom-15" style="opacity: 0;">{{ $t('开始预估') }}</div>
					<div class="start-btn _flex-row-bet-mid _pc-pad-left-30 _pc-pad-right-15 _pc-w-365" @click="reckonBtn()">
						{{ $t('开始预估') }}
					</div>
				</div>
			</div>
			<div class="estimate-res"  v-if="true">
				<div class="title" v-if="count>0">
					{{ $t('已为您查询到线路方案：') }}{{count}}
				</div>
				<div class="_flex-row-mid _pc-mar-top-15 _pc-pad-bottom-30 _pc-w-950" style="color: #4284DC;" v-if="count>0">
					<div class="iconfont2 icon-zhuyi-copy _pc-font-20 _pc-mar-right-10"></div>
					<div class="_pc-font-16" style="line-height: 1.7;">{{ $t('以下结果仅供参考，详情请咨询客服') }}</div>
				</div>
				<div class="res-box">
					<div class="li" v-for="(item,index) in line_list" :key="index">
						<div class="_pc-font-28 _pc-mar-bottom-20">{{ item.express_name }}</div>
						<div class="_pc-mar-bottom-20" style="color: #4284DC;">
							<span class="_pc-font-18">{{ $t('运费') }}：</span>
							<span class="_pc-font-32 _b">{{ item.price }}</span>
							<span class="_pc-font-18 _b _pc-mar-left-10">{{ $t('卢布') }}</span>
						</div>
						<div class="_pc-font-18 _w-max _flex-row _pc-mar-bottom-20" :class="{'_flex-col':$i18n.locale == 'ru'}">
							<div :style="$i18n.locale == 'ru'?'':'width: 50%;'">{{ $t('首重') }}{{ item.start_fee+'RUB/'+item.start_weight/1000+'kg' }}</div>
							<div :style="$i18n.locale == 'ru'?'margin-top: 10px;':'width: 50%;'">{{ $t('续重') }}{{ item.pre_weight_fee+'RUB/'+item.init_weight/1000+'kg'}}</div>
						</div>
						<div class="_pc-mar-bottom-10">{{ $t('物品限制') }}</div>
						<div class="_pc-mar-bottom-15" style="color: #999999;"><p>{{ item.express_articles_limit }}</p></div>
						<!-- <div class="_pc-mar-bottom-5">{{ $t('重量限制') }}：32kg以内</div> -->
						<div>{{ $t('长度限制') }}：{{ item.express_lenght_limit }}</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 预估运费 -->
		<div class="_pc-display-none mb-main-box" style="width: 100%;">
			<div class="mb-main-box-li">
				<div class="mb-li mb-grid-column-max-span">
					<div class="name">{{ $t('收货地址') }}</div>
					<div class="input-box">
						<div @click="country_show = !country_show" style="color: #999999;flex: 1;" :style="country_index!==''?'color:#333':'color:#999'">{{country_index!==''? country_name:$t('请选择')}}</div>
						<div @click="country_show = !country_show">
							<div class="iconfont2 icon-xiala" style="color: #999;"></div>
						</div>
						<div class="select-address-container" v-if="country_show">
							<div class="select-item" v-for="(item,index) in country_list" :key="index" :title="$t('收货地址')+'\n '+item" @click="getCountrys(index,item)">{{item.name}}</div>
						</div>
					</div>
				</div>
				<div class="mb-li">
					<div class="name">{{ $t('重量') }}</div>
					<label class="input-box">
						<input style="width: 5rem;" type="number" :placeholder="$t('请输入')" @keydown="NumberInput" v-model="weight">
						<div>kg</div>
					</label>
				</div>
				<div class="mb-li">
					<div class="name">{{ $t('长度') }}</div>
					<label class="input-box">
						<input style="width: 5rem;" type="number" :placeholder="$t('请输入')" @keydown="NumberInput" v-model="length">
						<div>cm</div>
					</label>
				</div>
				<div class="mb-li">
					<div class="name">{{ $t('wide') }}</div>
					<label class="input-box">
						<input style="width: 5rem;" type="number" :placeholder="$t('请输入')" @keydown="NumberInput" v-model="width">
						<div>cm</div>
					</label>
				</div>
				<div class="mb-li">
					<div class="name">{{ $t('height') }}</div>
					<label class="input-box">
						<input style="width: 5rem;" type="number" :placeholder="$t('请输入')" @keydown="NumberInput" v-model="height">
						<div>cm</div>
					</label>
				</div>
				<div class="mb-li mb-grid-column-max-span">
					<div class="search-btn" @click="reckonBtn()">{{ $t('开始预估') }}</div>
				</div>
			</div>
			<!-- 预估运费结果 -->
			<div class="mb-estimate-res" v-if="count>0">
				<div class="mb-title">
					{{ $t('已为您查询到线路方案：') }}{{count}}
				</div>
				<div class="mb-zhuyi">
					<div class="iconfont2 icon-zhuyi-copy" style="font-size: 0.75rem;"></div>
					<div style="margin-left: 0.375rem;">{{ $t('以下结果仅供参考，详情请咨询客服') }}</div>
				</div>
				<div class="mb-li">
					<div class="mb-li-item" v-for="(item,index) in line_list" :key="index">
						<div class="item-header">{{ item.express_name }}</div>
						<div class="item-main">
							<div class="mb-grid-column-max-span" style="color: #4284DC;">
								<span>{{ $t('运费') }}：</span>
								<span>
									<strong style="margin-right: 0.25rem;font-size: 0.9375rem;">
									{{ item.price }}
									</strong>
									{{ $t('卢布') }}
								</span>
							</div>
							<div>
								<span>{{ $t('首重') }}：</span>
								<span>{{ item.start_fee+'RUB/'+item.start_weight/1000+'kg' }}</span>
							</div>
							<div>
								<span>{{ $t('续重') }}：</span>
								<span>{{ item.pre_weight_fee+'RUB/'+item.init_weight/1000+'kg'}}</span>
							</div>
							<div class="mb-grid-column-max-span">
								<span>{{ $t('物品限制') }}：</span>
								<span style="color: #999999;">
									{{ item.express_articles_limit }}
								</span>
							</div>
							<!-- <div>
								<span>重量限制：</span>
								<span>32kg以内</span>
							</div> -->
							<div class="mb-grid-column-max-span">
								<span>{{ $t('长度限制') }}：</span>
								<span>{{ item.express_lenght_limit }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getCountry, predictPriceList } from '@/api/jiyun'
	export default {
		data() {
			return {
				country_list:[],
				country_index:'',
				country_show:false,
				lang: 'zh_cn',		// 语言
				area_id: 0,			// 地区ID
				country_name: '',	// 地区名称
				weight: '',			// 重量
				length: '',			// 长
				width: '',			// 宽
				height: '',			// 高
				count: 0,			// 线路总数
				line_list: [],		// 线路
				agent_info: {},
				select_show:false
			}
		},
		created(){
			this.lang = localStorage.getItem('think-lang') || 'zh_cn';
			this.getCountry();
		},
		methods: {
			// 获取国家列表
			getCountry(){
				getCountry().then((res)=>{
					this.country_list = res.data;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			// 选择国家
			getCountrys(index,country) {
				if(index==-1){
					this.country_show = !this.country_show;
					this.select_show = !this.select_show;
				} else {
					this.country_show = !this.country_show;
					this.select_show = !this.select_show;
					this.country_index = index;
					this.area_id = country.id;
					this.country_name = country.name;
				}
			},
			NumberInput:function(e) {
				if(e.keyCode == 69) {
					e.returnValue = false
					return false
				}
			},
			// 开始预估
			reckonBtn() {
				let that = this;
				let param = {
					area_id: that.area_id,
					weight: that.weight,
					length: that.length,
					width: that.width,
					height: that.height
				}
				predictPriceList(param).then((res)=>{
					that.line_list = res.data.list;
					that.count = that.line_list.length;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			}
		}
	}
</script>

<style scoped>
	input {
		height: 100%;
		outline: none;
	}
	input::placeholder {
		color:#999
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
	    -webkit-appearance: none;
	}
	
	input[type="number"] {
	    -moz-appearance: textfield;
	}
	.main-box {
		width: 100%;
	}
	.main-box-li {
		width: 100%;
		position: relative;
	}
	.main-box-li .item .input-box {
		height: 70px;
		border-radius: 14px;
		background: #FFFFFF;
		border: 1px solid #D6D6D6;
	}
	.main-box-li .item .input-box:hover {
		border: 1px solid #999;
	}
	.main-box-li .item .start-btn {
		background-color: #4284DC;
		border-radius: 14px;
		color: #ffffff;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 24px;
		height: 70px;
	}
	.main-box-li .item .start-btn:hover {
		opacity: 0.4;
		cursor: pointer;
	}
	.main-box-li .item .select-box {
		position: absolute;
		width: 100%;
		min-height: 70px;
		z-index: 99;
		top: 110px;
		left: 0;	
		background-color: #ffffff;
	}
	.main-box-li .item  .select-box .li {
		width: 100%;
		height: 70px;
		padding: 0px 30px;
		line-height: 70px;
		font-size: 18px;
		/* display: flex; */
		/* align-items: center; */
		
		/* justify-content: center; */
	}
	.line-1 {
		
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.main-box-li .item .select-box .li:hover {
		border: 1px solid #D6D6D6;
		
	}
	.main-box-li .item .icon-xiala {
		font-size: 32px;
		color: #999999;
	}
	
	
	
	/* 预估结果 */
	.estimate-res {
		color: #333;
		margin-top: 57px;
	}
	.estimate-res .title {
		font-size: 32px;
		font-weight: bold;
	}
	.estimate-res .res-box {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	.estimate-res .res-box .li {
		width: calc((100% - 32px) / 2);
		background-color: #ffffff;
		border-radius: 8px;
		padding: 37px 30px;
		font-size: 18px;
		flex-shrink: 0;
		margin-bottom: 15px;
	}
	/* 移动端css */
	.mb-main-box {
		width: 100%;
	}
	.mb-main-box .mb-main-box-li {
		margin-top: 0.625rem;
		display: grid;
		grid-template-columns: repeat(2,1fr);
		grid-column-gap: 0.9375rem;
		grid-row-gap: 1rem;
	}
	.mb-main-box .mb-main-box-li .mb-li {
		flex: 1;
/* 		background: #f0ffe9; */
	}
	.mb-main-box .mb-main-box-li .mb-li .name {
		font-size: 0.9375rem;
		margin-bottom: 0.375rem;
	}
	.mb-main-box .mb-main-box-li .mb-li .input-box {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0rem 0.625rem;
		background: #fff;
		border: 1px solid #D6D6D6;
		border-radius: 0.5rem;
		height: 2.8125rem;
		position: relative;
	}
	/* 下拉选择 */
	.mb-main-box .mb-main-box-li .mb-li .input-box .select-address-container {
		position: absolute;
		left: 0;
		top: 110%;
		background-color: #fff;
		border-radius: 0.5rem;
		width: 100%;
		min-height:  2.8125rem;
		max-height: calc(2.8125rem * 6);
		border: 1px solid #D6D6D6;
		z-index: 2;
		box-shadow: -1px 7px 9px 4px rgba(0, 0, 0, 0.3);
		overflow: hidden;
	}
	.mb-main-box .mb-main-box-li .mb-li .input-box .select-address-container .select-item {
		width: 100%;
		padding: 0rem 0.9375rem;
		display: flex;
		align-items: center;
		height: 2.8125rem;
		border-bottom: 1px solid #f4f4f4;
	}
	/* .mb-main-box .mb-main-box-li .mb-li .input-box .select-address-container .select-item:hover {
		background-color: rgba(66, 132, 220, 0.3);
		color: #999;
	} */
	.mb-main-box .mb-main-box-li .mb-li .input-box .select-address-container .select-item:last-child {border-bottom: none;}
	.mb-main-box .mb-main-box-li .mb-li .search-btn {
		width: 100%;
		height: 2.8125rem;
		border-radius: 0.5rem;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #4284DC;
		overflow: hidden;
	}
	.mb-estimate-res {
		width: 100%;
		color: #333;
		margin-top: 1.25rem;
	}
	.mb-estimate-res .mb-title {
		font-size: 1.125rem;
		font-weight: bold;
	}
	.mb-estimate-res .mb-zhuyi {
		display: flex;
		align-items: center;
		color: #4284DC;
		font-size: 0.75rem;
		margin-top: 0.375rem;
	}
	.mb-estimate-res .mb-li {
		width: 100%;
		margin-top: 0.625rem;
		display: flex;
		flex-direction: column;
		grid-row-gap: 0.875rem;
	}
	.mb-estimate-res .mb-li .mb-li-item {
		width: 100%;
		background: #fff;
		padding: 0.625rem 0.9375rem;
	}
	.mb-estimate-res .mb-li .mb-li-item .item-header {
		font-weight: bold;
		font-size: 1.025rem;
	}
	.mb-estimate-res .mb-li .mb-li-item .item-main {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 0.9375rem;
		grid-row-gap: 0.25rem;
		font-size: 0.8125rem;
	}
	/*屏幕在480px到768之间（主要是手机屏幕）*/
	@media screen and (max-width: 768px){
		.display-none {
			display: none !important;
		}
		.mb-display-block {
			display: block;
		}
		/* pad */
		.mb-pad-0 {
			padding: 0;
		}
		.mb-pad-x-15 {
			padding-left: 0.9375rem;
			padding-right: 0.9375rem;
		}
		.mb-pad-x-25 {
			padding-left: 1.5625rem;
			padding-right: 1.5625rem;
		}
		.mb-pad-x-30 {
			padding-left: 1.875rem;
			padding-right: 1.875rem;
		}
		.mb-pad-y-15 {
			padding-top: 0.9375rem;
			padding-bottom: 0.9375rem;
		}
		.mb-pad-y-25 {
			padding-top: 1.5625rem;
			padding-bottom: 1.5625rem;
		}
		.mb-pad-y-30 {
			padding-top: 1.875rem;
			padding-bottom: 1.875rem;
		}
		/* mar */
		.mb-mar-0 {
			margin: 0;
		}
		.mb-mar-top-30 {
			margin-top: 1.875rem;
		}
		.mb-mar-top-50 {
			margin-top: 3.125rem;
		}
		.mb-mar-right-15 {
			margin-right: 0.9375rem;
		}
		.mb-mar-y-15 {
			margin-top: 0.9375rem;
			margin-bottom: 0.9375rem;
		}
		.mb-mar-y-25 {
			margin-top: 1.5625rem;
			margin-bottom: 1.5625rem;
		}
		/* width */
		.mb-w-max {
			width: 100%;
		}
		.mb-w-70 {
			width: 4.375rem;
		}
		.mb-w-100 {
			width: 6.25rem;
		}
		.mb-w-18 {
			width: 1.125rem;
		}
		/* height */
		.mb-h-60 {
			height: 3.75rem;
		}
		/* font */
		.mb-font-15 {
			font-size: 0.9375rem;
		}
		.mb-font-20 {
			font-size: 1.25rem;
		}
		.mb-font-25 {
			font-size: 1.5625rem;
		}
		.mb-font-30 {
			font-size: 1.875rem;
		}
		.mb-font-35 {
			font-size: 2.1875rem;
		}
		.mb-text-center {
			text-align: center;
		}
		/* flex */
		.mb-flex-1 {
			flex: 1;
		}
		.mb-flex-2 {
			flex: 2;
		}
		.mb-flex-col-center-mid {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
		.mb-flex-row-bet-mid {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
		}
		.mb-flex-shrink {
			flex-shrink: 0;
		}
		.mb-border-radius-10 {
			border-radius: 0.625rem;
		}
		.mb-border-radius-15 {
			border-radius: 0.9375rem;
		}
		.mb-grid-column-max-span{
			grid-column: 1 / -1;
		}
		.mb-order-1 {order: 1;}
		.mb-order-2 {order: 2;}
		.mb-order-3 {order: 3;}
		.mb-order-4 {order: 4;}
		.mb-order-5 {order: 5;}
		.mb-order-6 {order: 6;}
		.mb-order-7 {order: 7;}
		.mb-order-8 {order: 8;}
		.mb-order-9 {order: 9;}
		.mb-order-10 {order: 10;}
	}
</style>